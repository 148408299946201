<template>
  <div>
    <OrdersList
      :orderData="orderData"
      :totalDocs="totalDocs"
      :page="page"
      :noDataText="noDataText"
      @fetchList="getOrdersList"
      @viewOrderDetail="viewOrderDetail"
    />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import OrdersList from "../../../components/WebStore/orders/OrdersList";

export default {
  components: {
    OrdersList
  },
  data() {
    return {
      totalDocs: 0,
      page: 1,
      orderData: [],
      noDataText: 'Loading...'
    };
  },
  methods: {
    ...mapActions("storeOrder", [
      "getOrderList"
    ]),
    async getOrdersList(dataTableParams) {
      let self = this;
      this.getOrderList(dataTableParams).then( async (res) => {
        self.orderData = res.data.data.docs;
        self.totalDocs = res.data.data.pagination.total || 0;
        self.page = res.data.data.pagination.page;
        self.currentPage = res.data.data.pagination.page;
        self.noDataText = 'No Orders Available'
      });
    },
   viewOrderDetail(id) {
      this.$router.push({
        name: "AdminStoreViewOrder",
        params: { orderId: id }
      });
    }
  }
};
</script>
